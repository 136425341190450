function McUtils() {
}
McUtils.prototype = {
    constructor: McUtils,

    isElementInView: function(element, fullyInView) {
        var pageTop = jQuery(window).scrollTop();
        var pageBottom = pageTop + jQuery(window).height();
        var elementTop = jQuery(element).offset().top;
        var elementBottom = elementTop + jQuery(element).height();
        if (fullyInView === true) {
            return ((pageTop < elementTop) && (pageBottom > elementBottom));
        }
        else {
            return ((elementTop <= pageBottom) && (elementBottom >= pageTop));
        }
    },

    isElementBelowView: function (element) {
        var pageTop = jQuery(window).scrollTop();
        var pageBottom = pageTop + jQuery(window).height();
        var elementTop = jQuery(element).offset().top;

        return elementTop > pageBottom;
    },

    isElementAboveView: function (element) {
        var pageTop = jQuery(window).scrollTop();
        var elementTop = jQuery(element).offset().top;
        var elementBottom = elementTop + jQuery(element).height();

        return elementBottom < pageTop;
    }

};
export default new McUtils();
